
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { Upload } from "@element-plus/icons-vue";
import { ElUpload } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ApiMerchantCreditNotes } from "@/core/api";
import { modalShowListener } from "@/core/directive/function/common";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface File {
  file_upload_record_id: number;
  size: number;
  url: string;
}

export default defineComponent({
  name: "import-merchant-credit-notes-modal",
  props: {
    uploadUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const importMerchantInvoiceRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const fileList = ref<Array<File>>([]);
    const uploadList = ref<Array<any>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);

    const { showServerErrorMsg } = mixin();

    const formData = ref({
      file_upload_record_id: [] as any[],
    });

    const rules = ref({});

    const options = ref({});

    const onUploadChange = async (file, files) => {
      // console.log(file, files);
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file[]", item.raw, item.raw.name);
        }
      });
      // console.log(uploadList.value);
      // console.log(params.getAll("file[]"));
      // console.log(params, fileArr);
      debounceUpload(params, fileArr);
    };

    const onUploadRemove = (file) => {
      // console.log(file);
      // console.log(uploadRef.value?.uploadFiles);
    };

    const uploadFiles = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiMerchantCreditNotes.create(params);
      uploadLoading.value = false;
      if (data.code === 0) {
        emit("update-list");
        hideModal(importMerchantInvoiceRef.value);
        if (data.msg) {
          Swal.fire({
            text: data.msg,
            icon: "error",
          });
        }
      } else {
        showServerErrorMsg(data);
        uploadList.value = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadFiles, 100);

    const onUploadError = async (error, file, files) => {
      console.log(error, file, files);
    };

    const handleDiscard = () => {
      hideModal(importMerchantInvoiceRef.value);
    };

    const resetForm = () => {
      uploadRef.value!.clearFiles();
      formData.value = {
        file_upload_record_id: [],
      };
      fileList.value = [];
      uploadList.value = [];
      emit("reset-form");
    };

    onMounted(() => {
      modalShowListener(importMerchantInvoiceRef.value, () => {
        // init();
      });
      modalHideListener(importMerchantInvoiceRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      loading,
      formData,
      importMerchantInvoiceRef,
      rules,
      options,
      handleDiscard,
      resetForm,
      uploadRef,
      Upload,
      uploadLoading,
      fileList,
      uploadList,
      uploadHeaders,
      onUploadChange,
      onUploadRemove,
      onUploadError,
    };
  },
});
