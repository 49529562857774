
import { computed, defineComponent, onActivated, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiMerchantCreditNotes } from "@/core/api";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import {
  formatDate,
  modalShowListener,
} from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import {
  MerchantCreditNotesInfoSearchProduct,
  MerchantCreditNotesInfoSearchProductItem,
} from "@/core/directive/interface/merchantCreditNotes";
import { ScrollComponent } from "@/assets/ts/components";

export default defineComponent({
  components: { SkuInfo, ProductInfo },
  name: "settlement-merchant-credit-notes-add-product",
  emits: ["update-list", "reset-form"],
  props: {
    merchantId: {
      type: Number,
      default: 0,
    },
    merchantMap: {
      type: Map,
      default: new Map([]),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const merchantOrderList = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonHtmlType>(null);

    const { showValidateErrorMsg } = mixin();

    const formData = ref({
      ids: [] as number[],
      items: [] as MerchantCreditNotesInfoSearchProduct[],
    });

    const rules = ref({});

    const options = ref({});

    const toggleItemSelect = (item: MerchantCreditNotesInfoSearchProduct) => {
      item.isChecked = !item.isChecked;
      let isChecked = item.isChecked;
      item.items.forEach((row) => {
        row.isChecked = isChecked === true ? false : true;
        toggleSubSelect(item, row);
      });
    };

    const toggleSubSelect = (
      item: MerchantCreditNotesInfoSearchProduct,
      sub: MerchantCreditNotesInfoSearchProductItem
    ) => {
      sub.isChecked = !sub.isChecked;
      const checkIndex = item.items.findIndex((sub) => !sub.isChecked);
      item.isChecked = checkIndex === -1;
    };

    const getSubmitData = computed(() => {
      const productList: MerchantCreditNotesInfoSearchProductItem[] = [];
      formData.value.items.map((productItem) => {
        if (productItem.isChecked) {
          productList.push(...productItem.items);
        } else {
          productItem.items.map((item) => {
            if (item.isChecked) {
              productList.push(item);
            }
          });
        }
      });
      return productList;
    });

    const expandSub = (item: MerchantCreditNotesInfoSearchProduct) => {
      item.isExpand = !item.isExpand;
    };

    const isDisabledSubmit = computed(() => {
      let isFlag = false;
      if (getSubmitData.value.length === 0) {
        isFlag = true;
      }
      return isFlag;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        const data = getSubmitData.value;
        if (valid) {
          emit("update-list", data);
          hideModal(merchantOrderList.value);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getShowInfo = async () => {
      const { data } = await ApiMerchantCreditNotes.searchItem({
        merchant_id: props.merchantId,
      });
      if (data.code == 0) {
        let items: MerchantCreditNotesInfoSearchProduct[] = data.data;
        items.forEach((item: MerchantCreditNotesInfoSearchProduct) => {
          item.isChecked = false;
          item.isExpand = true;
          item.items.forEach(
            (row: MerchantCreditNotesInfoSearchProductItem) => {
              row.isChecked = false;
              row.request_number = item.request_number;
              row.request_date = item.request_date;
              row.confirmed_qty = row.qty;
              row.product_identifier_id = row.__show.product_identifier_id;
            }
          );
        });
        formData.value.items = items;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onActivated(() => {
      ScrollComponent.reinitialization();
    });

    onMounted(() => {
      ScrollComponent.reinitialization();
      modalShowListener(merchantOrderList.value, () => {
        getFormInfo();
      });
      modalHideListener(merchantOrderList.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formatDate,
      props,
      merchantOrderList,
      loading,
      formData,
      submitButton,
      formRef,
      options,
      rules,
      toggleItemSelect,
      toggleSubSelect,
      expandSub,
      isDisabledSubmit,
      submit,
      resetForm,
      getShowInfo,
    };
  },
});
