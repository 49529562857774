/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-07 10:51:35
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-03-12 14:39:37
 * @FilePath: /settlement-frontend/src/core/directive/interface/merchantCreditNotes.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { NumberOrString } from "../type/common";
import { CurrencyType } from "../type/order";
import { PriceCalcMode } from "./orderRequest";

export const merchantCreditNotesInfo: MerchantCreditNotesInfo = {
  id: 0,
  credit_note_number: "",
  status: 0,
  credit_note_date: "",
  currency: "",
  total_amount: "0.00",
  external_order_number: "",
  credit_note_file: 0,
  merchant: "",
  channel: "",
  business_entity_id: 1,
  shipping_charges: "0.00",
  shipping_tax_rate: 0,
  overall_notes: "",
  accounting_month: "",
  payment_date: "",
  price_calc_mode: "net",
  __show: {
    status: "",
    invoice_file: "",
    file_size: 0,
    taxRates: [],
    merchant_name: "",
  },
  relation_credit_note_item: [],
};

export const merchantCreditNotesInfoItem: MerchantCreditNotesInfoItem = {
  id: 0,
  return_id: [],
  request_items_id: 0,
  article_no: "",
  brand_name: "",
  article_name: "",
  size: "",
  color: "",
  sku_id: "",
  sku_identifier: "",
  product_identifier_id: "",
  currency: "",
  unit_net_amount: 0,
  unit_gross_amount: 0,
  discount_type: CurrencyType.Amount,
  discount: 0,
  tax_rate: 0,
  qty: 0,
  confirmed_qty: 0,
  request_number: "",
  request_date: "",
  __show: {
    channel_id: 0,
    channel_name: "",
    channel_type: "",
    article_no: "",
    brand_name: "",
    size: "",
    color: "",
    product_name: "",
    product_gallery: "",
    product_identifier_id: "",
    tax_rates: [],
    discount_rule: 0,
  },
  isChecked: false,
};

interface MerchantCreditNotesCommon {
  id: NumberOrString;
  credit_note_number: string;
  status: number;
  credit_note_date: string;
  currency: string;
  total_amount: string;
  external_order_number: string;
  credit_note_file: NumberOrString;
  merchant: NumberOrString;
  channel: string;
  business_entity_id: NumberOrString;
  shipping_charges: string;
  shipping_tax_rate: number;
  overall_notes: string;
  accounting_month: string;
  payment_date: string;
}

export interface MerchantCreditNotesTable extends MerchantCreditNotesCommon {
  previewDisabled: boolean;
  downloadDisabled: boolean;
  deleteDisabled: boolean;
  __show: MerchantCreditNotesTableShow;
}

interface MerchantCreditNotesTableShow {
  status: string;
  invoice_file: string;
  file_size: number;
  merchant_name: string;
}

export interface MerchantCreditNotesInfo extends MerchantCreditNotesCommon {
  price_calc_mode: PriceCalcMode;
  __show: MerchantCreditNotesInfoShow;
  relation_credit_note_item: MerchantCreditNotesInfoItem[];
}

interface MerchantCreditNotesInfoShow extends MerchantCreditNotesTableShow {
  taxRates: number[];
}

export interface MerchantCreditNotesInfoItem {
  id: NumberOrString;
  return_id: number[];
  request_items_id: NumberOrString;
  article_no: string;
  brand_name: string;
  article_name: string;
  size: string;
  color: string;
  sku_id: string;
  sku_identifier: string;
  product_identifier_id: string;
  currency: string;
  unit_net_amount: number;
  unit_gross_amount: number;
  discount_type: CurrencyType;
  discount: number;
  tax_rate: number;
  qty: number;
  confirmed_qty: number;
  request_number: string;
  request_date: string;
  __show: MerchantCreditNotesInfoItemShow;
  isChecked: boolean;
}

interface MerchantCreditNotesInfoItemShow {
  channel_id: number;
  channel_name: string;
  channel_type: string;
  article_no: string;
  brand_name: string;
  size: string;
  color: string;
  product_name: string;
  product_gallery: string;
  product_identifier_id: string;
  tax_rates: number[];
  discount_rule: number;
}

export interface MerchantCreditNotesInfoSearchProduct {
  request_number: string;
  request_date: string;
  reference: string;
  total_qty: number;
  total_net_amount: number;
  items: MerchantCreditNotesInfoSearchProductItem[];
  // 自定义字段
  isChecked: boolean;
  isExpand: boolean;
}

export interface MerchantCreditNotesInfoSearchProductItem {
  request_items_id: NumberOrString;
  sku_identifier: string;
  currency: string;
  unit_net_amount: string;
  unit_gross_amount: string;
  discount_type: CurrencyType;
  discount: number;
  tax_rate: number;
  qty: number;
  return_id: number[];
  __show: MerchantCreditNotesInfoSearchProductItemShow;
  // 自定义字段
  product_identifier_id: string;
  confirmed_qty: number;
  request_number: string;
  request_date: string;
  isChecked: boolean;
}

interface MerchantCreditNotesInfoSearchProductItemShow {
  article_no: string;
  brand_name: string;
  article_name: string;
  size: string;
  color: string;
  product_identifier_id: string;
  product_name: string;
  product_gallery: string;
  tax_rates: number[];
  discount_rule: number;
}
